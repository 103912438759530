import React, { useContext } from "react";
import { ResponsiveContext } from "../../context/ResponsiveContext/ResponsiveContext";
import {
  Container,
  Grid,
  GridGutter,
  GridItem,
  GridSpan,
  classNames,
  Padding,
  FlexDirection,
  BorderWidth,
  BackgroundColor,
  TextColor,
  FontSize,
  Margin
} from "@snoam/pinata";
import FooterContact from "./FooterContact";
import FooterSuggestion from "./FooterSuggestion";
import FooterProfile from "./FooterProfile";
import {useParams} from "react-router-dom";

const styleClass = {
  container: (desktop: boolean) =>
    classNames(
      BackgroundColor.BG_NEUTRAL_7,
      TextColor.TEXT_NEUTRAL_3,
      {
        [Padding.PX_32]: desktop,
        [Margin.MT_48]: desktop
      },
      Padding.PX_4,
      Padding.LG_PY_6
    ),
  item: classNames(
    FlexDirection.FLEX_COL,
    Padding.PY_4,
    Padding.PX_0,
    Padding.LG_PX_4,
    Margin.MX_4,
    Margin.LG_MX_0,
    FontSize.TEXT_BASE
  ),
  topItem: (mobile: boolean = false) =>
    classNames({
      [BorderWidth.BORDER_B_1]: mobile
    })
};

const Footer: React.FC<{}> = () => {
  const { mobile, tablet, portrait, desktop } = useContext(ResponsiveContext);
  const { agreementNumber } = useParams<{ agreementNumber?: string; }>()

  const openCookieConsent = () => {
    window?._tcf_?.showPrivacyManager()
  };

  return (
    <Container className={styleClass.container(desktop)}>
      <Grid gutter={GridGutter.LEFT_NONE} className={Padding.PB_10}>
        <GridItem
          span={[GridSpan.LARGE_4, GridSpan.XSMALL_12]}
          className={classNames(
            styleClass.item,
            styleClass.topItem(mobile || (tablet && portrait))
          )}
          id={"footer-contact"}
        >
          <FooterContact />
        </GridItem>
        <GridItem
          span={[GridSpan.LARGE_4, GridSpan.XSMALL_12]}
          className={classNames(styleClass.item)}
        >
          <FooterSuggestion openCookieModal={openCookieConsent} />
        </GridItem>
        <GridItem
          span={[GridSpan.LARGE_4, GridSpan.XSMALL_12]}
          className={styleClass.item}
        >
          <FooterProfile agreementNumber={agreementNumber} />
        </GridItem>
      </Grid>
    </Container>
  );
};

export default Footer;
